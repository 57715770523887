<template>
  <div class="c-breadcrumb u-3-of-5">
    <router-link exact :to="{ name: 'cis.sessions'}" class="c-breadcrumb__item c-breadcrumb__link">
      {{ $t('cis') }}: {{ $t('home') }}
    </router-link>
    <template v-if="session">
      <span class="c-breadcrumb__item">{{session.name}}</span>
    </template>
  </div>
</template>

<script>
  import find from 'lodash/find';

  export default {
    name: 'cis-breadcrump',
    computed: {
      session: function() {
        return find(this.$store.state.sessions.items, (item) => item.id === this.$route.params.sessionId)
      },
    },
  }
</script>
